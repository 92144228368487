@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;} 
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;} 
}

.fadeOut {
  -webkit-animation-duration: 3s;animation-duration: 3s;
  -webkit-animation-fill-mode: both;animation-fill-mode: both;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
.fadeIn {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-duration: 3s;animation-duration: 3s;
  -webkit-animation-fill-mode: both;animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}


.logo-gather-demo-edit-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #f1f1f1;
    padding: 0 5%;
    line-height: 45px;
  }
  
  .logo-gather-demo-edit-wrapper ul {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  
  .logo-gather-demo-edit-wrapper ul li:first-child {
    margin-left: 0;
  }
  
  .logo-gather-demo-edit-wrapper ul li {
    float: left;
    vertical-align: middle;
    margin: 0 5px;
  }
  
  .logo-gather-demo-wrapper {
    position: relative;
    background: transparent;
    overflow: hidden;
    height: 500px;
  }
  
  .logo-gather-demo-wrapper .point-wrapper {
    position: absolute;
  }
  
  .logo-gather-demo-wrapper .point {
    border-radius: 100%;
  }
  
  .logo-gather-demo-wrapper .right-side {
    width: 300px;
    height: 360px;
    position: absolute;
    right: 0;
    top: 50px;
    bottom: 0;
    left:70%;
    // margin: auto;
     @media screen and (max-width: 900px) {
      left: 11%;
      top:25%;
      transform: rotate(5deg);
      }
  }
  .logo-gather-demo-wrapper .logo-side {
    width: 300px;
    height: 360px;
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 0;
    left: 70%;
     @media screen and (max-width: 900px) {
      left: 11%;
      top:20%;
      transform: rotate(5deg);
      }
  
    // margin: auto;
  }
  
  .hidden{
    display: none !important;
    visibility: hidden !important;
  }
  .show{
    display: block !important;
    visibility: visible !important;
  }
  
  .logo-gather-demo-wrapper .right-side * {
    pointer-events: none;
  }
  
  @media screen and (max-width: 414px) {
    .exhibition-details-demo {
      overflow: hidden;
    }
  
    .logo-gather-demo-edit-wrapper {
      transform: translateY(100%);
      transition: transform .45s ease-in-out;
    }
  
    .logo-gather-demo-edit-wrapper.open{
      transform: translateY(0);
    }
  
    .logo-gather-demo-edit-wrapper .anticon-down{
      transition: transform .45s ease-in-out;
    }
  
    .logo-gather-demo-edit-wrapper.open .anticon-down{
      transform: rotate(180deg);
    }
  
    .logo-gather-demo-edit-wrapper > div {
      width: 90%;
      line-height: 24px !important;
      margin-bottom: 5px;
    }
  
    .exhibition-details-demo .edit-button{
      position: absolute;
      top: -20px;
      width: 30px;
      height: 20px;
      border-radius: 30px 30px 0 0;
      background: #f1f1f1;
      text-align: center;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.15);
    }
  
    .logo-gather-demo-edit-wrapper ul {
      margin: 5px auto;
    }
  
    .phone-float-none {
      clear: both;
      margin-left: 0 !important;
    }
  
    .none {
      display: none;
    }
  
  }