//==========================
//   APP BUTTON
//==========================
.app--button {
  @include text(14px, 0, normal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  outline: none;
  border: 1px solid white;
  background-color: $SECONDRY;
  // background-color: #2d3748;
  color: $WHITE;
  border-radius: 4px;
  @media only screen and(max-width:992px) {
    font-size: 14px;
    height: 34px;
    padding: 5px;
    margin-top: 14px;
    border-radius: 6px;
  }
  &:hover {
    color: lightblue;
  }
}

.app__heading {
  @include text(40px, 0, 900);
  color: $PRIMARY;
  margin: 30px 0px;
  text-align: center;
}
.app__description {
  @include text(22px, 0, 600);
  color: $PRIMARY;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.border-bottom {
  border-bottom: 2px solid $PRIMARY !important;
}
