.home-page {
  .page__header-intro {
    // background-image: linear-gradient(
    //   to right,
    //   #52c1ec,
    //   #00aff4,
    //   #009bfa,
    //   #0085fb,
    //   #006af5
    // );
    background-color: $WHITE;
    .page__intro {
      position: relative;
      width: 100%;

      // border-radius: 6px;
      // max-width: 1200px;
      // min-height: 600px;
      // padding: 50px 20px;
      // display: flex;
      // justify-content: space-around;
      // align-items: center;

      @media screen and (max-width: 900px) {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
      }

      .intro__content {
        position: absolute;
        top: 40%;
        left: 10%;
        @media screen and (max-width: 900px) {
          top: 0;
          left: 5%;
        }
        z-index: 10;
        .content__heading {
          @include text(40px, 0, 600);
          margin: 33px 0px;
        }
        .content__description {
          @include text(18px, 0, normal);
          // max-width: 933px;
          width: 100%;
          line-height: 30px;
          margin-bottom: 10px;
          color: $DESC__TEXT;

          @media screen and (max-width: 900px) {
            @include text(13px, 0, normal);
            color: $DESC__TEXT;
          }
          .description__heading {
            @include text(30px, 0, normal);
            color: $DESC__TEXT;

            @media screen and (max-width: 900px) {
              @include text(20px, 0, 600);
              color: $DESC__TEXT;
            }
          }
        }
      }
      .intro_pic {
        margin-left: 60px;
        width: 300px;
        @media screen and (max-width: 900px) {
          margin-top: 20px;
          width: 50%;
          object-fit: cover;
          margin-left: 0;
        }
      }
    }
  }

  .page__characters {
    background: $WHITE;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f7fa;
    padding: 40px 0px;

    .characters__heading {
      @include text(22px, 0, 700);
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      width: 100%;
      color: $HEADER__TEXT;
    }
    .characters__description {
      @include text(15px, 0, "");
      padding: 20px 0px;
      width: 100%;
      margin: auto;
      text-align: center;
      color: #6b7280;
      white-space: break-spaces;
      line-height: 22px;
      max-width: 40rem;
      span {
        color: #6b7280;
      }
      &.mobile {
        display: none;
        @media screen and (max-width: 900px) {
          display: block;
        }
      }
      &.desktop {
        display: block;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      @media screen and (max-width: 900px) {
        width: 90%;
      }
    }
    .action {
      text-decoration: none;
    }
  }
  .page__box {
    padding: 40px;
    background: #f5f7fa;

    .box__content {
      padding: 20px;
      width: 320px;
      height: auto;
      background: #f5f7fa;
      border-radius: 5px;
      .content__title {
        @include text(22px, 0, 700);
        color: $HEADER__TEXT;
      }
      .content__descr {
        @include text(15px, 0, "", "");
        padding-top: 0.5rem;
        color: #6b7280;
      }
    }
  }
  .page__started {
    margin: 50px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .started_pic {
      margin-right: 60px;
      @media screen and (max-width: 900px) {
        width: 80%;
        object-fit: cover;
        margin-right: 0;
        margin-top: 20px;
      }
    }
    .started__content {
      .content__heading {
        @include text(40px, 0, 600);
        color: $PRIMARY;
        margin: 33px 0px;
      }
      .content__description {
        @include text(20px, 0, "");
        color: $PRIMARY;
        max-width: 933px;
        line-height: 30px;
        margin-bottom: 10px;
        margin-right: 60px;
      }
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.hero_info {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  .info__container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 20%;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    @media screen and (max-width: 900px) {
      width: 40%;
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .info__container_title {
      font-size: 30px;
      color: #7180a6;
      margin-bottom: 10px;
    }
    .info__container_subtitle {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 10px;
      color: #7180a6;
      @media screen and (max-width: 900px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
