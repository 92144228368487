.contact-page {
  // background-image: linear-gradient(
  //   to right,
  //   #52c1ec,
  //   #00aff4,
  //   #009bfa,
  //   #0085fb,
  //   #006af5
  // );
  .page__heading {
    @include text(22px, "", 700);
    // color: $WHITE;
    // padding: 80px 0px;
    padding: 100px 0px;
    background-image: url("../../assets/contact.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 44%;
    // background-color: #f5f7fa;
    // color: #2d3748;
    color: white;
    text-shadow: 1px 1px 5px #000;
    display: flex;

    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      background-size: 594px;
      background-position: center 35%;
      resize: both;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0px;
    }
  }
  .page__referrence {
    padding: 130px 80px 60px 80px;
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    background: $WHITE;
    @media screen and (max-width: 900px) {
      padding: 30px 20px;
      flex-direction: column;
    }
    .referrence__content {
      flex: 1 0 41%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content__title {
        @include text(22px, 0, 700);
        color: $HEADER__TEXT;
        @media screen and (max-width: 900px) {
          @include text(18px, 0, 700);
          color: $HEADER__TEXT;
        }
      }
      .content__descr {
        padding: 20px;
        @include text(18px, 0, 400);
        border-radius: 100px;
        cursor: pointer;
        text-decoration: none;
        color: #6b7280;

        @media screen and (max-width: 900px) {
          @include text(15px, 0, 400);
          color: #6b7280;
          padding: 10px 0px 20px;
        }
        &:hover {
          color: $PRIMARY;
        }
      }
    }
  }

  .tab__container {
    padding: 130px 80px 60px 80px;
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    background: $WHITE;
    @media screen and (max-width: 900px) {
      padding: 30px 20px;
      flex-direction: column;
    }
    .tab__links {
      width: 10%;
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      .tab__link {
        @media screen and (max-width: 900px) {
          margin: auto;
        }
        .tab__btn {
          @include text(18px, 0, "");
          line-height: 24px;
          color: #2d3748;
          border: 0;
          background: #ffffff;
          border-radius: 6px;
          cursor: pointer;
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
        }
        .active {
          background: #f5f7fa;
          color: #2d3748;
          border-color: #f5f7fa;
          border: 0;
          border-radius: 6px;
          @media screen and (max-width: 900px) {
            background: #f5f7fa;
            color: #2d3748;
            border-radius: 6px;
          }
        }
        .tab__btn:hover {
          background: #f5f7fa;
          color: #2d3748;
          border-color: #f5f7fa;
          border: 0;
          border-radius: 6px;
        }
      }
    }
    .tab__contents {
      width: 90%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      .tab__content {
        padding: 0px 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: $WHITE;
        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
        .referrence__content {
          flex: 1 0 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content__title {
            @include text(22px, 0, 700);
            color: $HEADER__TEXT;
            @media screen and (max-width: 900px) {
              @include text(18px, 0, 700);
              color: $HEADER__TEXT;
            }
          }
          .content__descr {
            padding: 20px;
            @include text(18px, 0, 400);
            border-radius: 100px;
            cursor: pointer;
            text-decoration: none;
            color: #6b7280;

            @media screen and (max-width: 900px) {
              @include text(15px, 0, 400);
              color: #6b7280;
              padding: 10px 0px 20px;
            }
            &:hover {
              color: $PRIMARY;
            }
          }
        }
        .page__content {
          margin-top: 6rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
          .page__questions {
            @include text(15px, 0, "");
            color: #6b7280;
            padding: 10px;
            background-color: $WHITE;
            text-align: center;

            @media screen and (max-width: 900px) {
              @include text(12px, 0, 400);
              color: #6b7280;
              padding: 10px;
            }
          }
          .page__questions_1 {
            @include text(15px, 0, "");
            color: #6b7280;
            padding: 10px;
            background-color: $WHITE;
            text-align: center;
            @media screen and (max-width: 900px) {
              @include text(12px, 0, 400);
              color: #6b7280;
              padding: 10px;
            }
          }
        }
        .contact__form {
          padding: 0px 80px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          color: #6b7280;
          width: 100%;
          @include text(16px, "", 400);
          @media screen and (max-width: 900px) {
            padding: 0;
          }
          .form__container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            @media screen and (max-width: 900px) {
              flex-direction: column;
              flex-wrap: wrap;
              width: 100%;
            }
          }
          .form__control {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            label {
              font-weight: 700;
              font-size: 22px;
              letter-spacing: 0;
              text-transform: initial;
              font-style: normal;
              color: #2d3748;
              padding-bottom: 0.8rem;
              @media screen and (max-width: 900px) {
                @include text(18px, 0, 700);
                color: #2d3748;
              }
            }
            select {
              @include text(14px, "", 400);
              color: #6b7280;
              border-radius: 5px;
              padding: 8px;
              border: 1px solid #6b728083;
              width: 100%;
            }
            textarea,
            input {
              border-radius: 5px;
              padding: 10px;
              border: 1px solid #6b728083;
            }
            input::placeholder,
            textarea::placeholder {
              @include text(14px, "", 400);
              color: #6b7280;
              font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
                Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI",
                "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            }
          }
          .w-half {
            width: 50%;
            @media screen and (max-width: 900px) {
              width: 100%;
            }
          }
          #captchaBox {
            margin-bottom: 1rem;
          }
          .error {
            color: rgb(247, 66, 66);
            font-size: 16px;
            font-weight: 500;
            line-height: 14px;
            margin-bottom: 1rem;
          }
          .submit__btn {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 15px;
            outline: none;
            border: 1px solid white;
            background-color: #00a4ef;
            color: #ffffff;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .thank_you {
    padding: 120px 40px;
    text-align: center;
    @include text(24px, 0, 700);
    color: $HEADER__TEXT;
  }
}
.geetest_feedback {
  display: none !important;
}
.geetest_logo {
  display: none !important;
}
.geetest_captcha .geetest_holder .geetest_content .geetest_tip_container,
.geetest_popup_wrap .geetest_holder .geetest_content .geetest_tip_container {
  justify-content: center !important;
}
.geetest_captcha .geetest_holder,
.geetest_popup_wrap .geetest_holder {
  width: 160px !important;
}
