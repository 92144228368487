.newsInsight-page {
  // background-image: linear-gradient(
  //   to right,
  //   #52c1ec,
  //   #00aff4,
  //   #009bfa,
  //   #0085fb,
  //   #006af5
  // );
  .page__heading {
    @include text(22px, "", 700);
    // color: $WHITE;
    // padding: 80px 0px;
    padding: 100px 0px;
    background-image: url("../../assets/newsInsights.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 44%;
    // background-color: #f5f7fa;
    // color: #2d3748;
    color: white;
    text-shadow: 1px 1px 5px #000;
    display: flex;

    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      background-size: 594px;
      background-position: center 35%;
      resize: both;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0px;
    }
  }
  .blog__container {
    padding: 50px 120px;
    display: flex;
    background: #fbfbfb;
    flex-wrap: wrap;
    @media screen and (max-width: 900px) {
      padding: 1rem;
    }

    .blog__filter {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      @include text(18px, "", 400);
      color: #2d3748;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      .blog__filter__btns {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        color: #2d3748;

        @media screen and (max-width: 900px) {
          align-items: center;
          flex-direction: row;
        }
      }
      span,
      button {
        @media screen and (max-width: 900px) {
          margin-left: 1rem;
        }
      }
      .active {
        color: #2d3748;
        background: #f5f7fa;
        border: 0;
        border-radius: 6px;
      }
      button {
        background: none;
        color: #2d3748;
        border: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        padding: 8px 16px;
        text-decoration: none;
        text-align: left;

        @media screen and (max-width: 900px) {
          color: #6b7280;
          background: none;
          border-radius: 3px;
        }
      }
    }
    .blog__articles {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 900px) {
        margin-top: 1rem;
        width: 100%;
      }
      .blog__article {
        width: 90%;
        text-decoration: none;
        color: #000;
        margin-bottom: 2rem;

        .article {
          max-width: 840px;
          padding: 1rem;
          transition: all 0.4s ease;
          display: flex;
          border-radius: 3px;
          background: #ffff;
          box-shadow: 0 6px 20px rgba(210, 215, 222, 0.25);
          @media screen and (max-width: 900px) {
            flex-direction: column;
          }
          .article__text {
            @media screen and (max-width: 900px) {
              width: 100%;
            }
            width: 70%;
            .upper__text {
              .tag {
                @include text(12px, "", 500);
                background: #beedff;
                color: #4299e1;
                padding: 5px 10px;
                border-radius: 3px;
              }
              .date {
                @include text(12px, "", 500);
                color: #7180a6;
                margin-left: 1rem;
              }
            }
            .article__title {
              @include text(20px, "", 700);
              line-height: 25px;
              color: $HEADER__TEXT;
              margin-top: 1rem;
            }
            .article__desc {
              @include text(14px, "", 400);
              line-height: 18px;
              color: $DESC__TEXT;
              margin-top: 1rem;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .article__img {
            @media screen and (max-width: 900px) {
              margin-top: 1rem;
            }
            height: 184px;
            aspect-ratio: 16/9;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 3px;
            }
          }
        }
        .article:hover {
          box-shadow: 0 6px 20px rgba(0, 106, 255, 0.25);
        }
      }
    }

    .post {
      width: 90%;
      @media screen and (max-width: 900px) {
        margin-top: 1rem;
        width: 100%;
      }
      .post__title {
        @include text(22px, "", 700);
        line-height: 42px;
        color: $HEADER__TEXT;
        @media screen and (max-width: 900px) {
          @include text(22px, "", 700);
          line-height: 30px;
        }
      }
      .post__desc {
        @include text(15px, 0, "");
        color: #6b7280;
        white-space: break-spaces;
        line-height: 22px;
        a {
          text-decoration: underline;
          @include text(15px, 0, 400);
          line-height: 25px;
          color: $PRIMARY;
        }
      }
      .post__img {
        width: 100%;
        max-width: 800px;
        aspect-ratio: 16/9;
        overflow: hidden;
        margin: auto;
        padding-top: 1rem;
        @media screen and (max-width: 900px) {
          height: auto;
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }
      .post__back {
        @include text(15px, 0, "");
        color: #6b7280;
        padding: 30px 0px;
        text-decoration: underline;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          padding: 20px 0px;
        }
        &:hover {
          color: $PRIMARY;
        }
      }
    }
  }
}
