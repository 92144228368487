.career-page {
  // background-image: linear-gradient(
  //   to right,
  //   #52c1ec,
  //   #00aff4,
  //   #009bfa,
  //   #0085fb,
  //   #006af5
  // );
  .page__heading {
    padding: 100px 0px;
    background-image: url("../../assets/career.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background-color: #f5f7fa;
    @include text(22px, 0, 700);
    color: white;
    text-shadow: 1px 1px 5px #000;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
      background-size: 700px;
      background-position: 55% center;

      display: flex;
      resize: both;

      flex-direction: column;
      align-items: center;
      padding: 50px 0px;
    }
  }
  .page__single-post {
    background: $WHITE;
    .post__heading {
      @include text(22px, 0, 700);
      color: $HEADER__TEXT;
      padding: 40px 80px;
      .post__location {
        @include text(15px, 0, 400);
        color: #6b7280;
        @media screen and (max-width: 900px) {
          @include text(13px, 0, 400);
          color: #6b7280;
        }
      }
      @media screen and (max-width: 900px) {
        padding: 30px 20px;
      }
    }

    .post__descr {
      @include text(15px, 0, "");
      color: #6b7280;
      padding: 0px 80px 40px;
      @media screen and (max-width: 900px) {
        padding: 10px 20px 20px;
      }
    }
    .post__back {
      @include text(15px, 0, "");
      color: #6b7280;
      padding: 0px 80px 40px;
      text-decoration: underline;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        padding: 10px 20px 20px;
      }
      &:hover {
        color: $PRIMARY;
      }
    }
  }
  .page__post {
    display: flex;
    flex-direction: column;
    padding: 20px 80px;
    .post__description {
      @include text(15px, 0, "");
      color: $DESC__TEXT;
      padding-bottom: 20px;
    }
  }
  .role {
    text-align: center;
    color: #6b7280;
    padding: 30px;
    font-size: 20px;
  }
  .page__position {
    margin: 15px 20px;
    padding: 1rem;
    background: $WHITE;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    @media screen and (max-width: 900px) {
      border-radius: 10px;
      padding: 10px 15px;
    }

    .position__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content__title {
        cursor: pointer;
        padding-bottom: 1rem;
        @include text(24px, 0, 600);
        color: #2d3748;
        @media screen and (max-width: 900px) {
          width: 70%;
          @include text(18px, 0, 600);
          color: #2d3748;
          padding-bottom: 0.5rem;
        }
      }
      .content__descr {
        padding: 20px;
        @include text(13px, 0, "");
        background: linear-gradient(90deg, #52c1ec, #006af5);
        border-radius: 100px;
        cursor: pointer;
      }
    }
    .position__location {
      @include text(15px, 0);
      margin-top: -20px;
      color: #6b7280;

      padding-bottom: 10px;
      @media screen and (max-width: 900px) {
        @include text(13px, 0, 400);
        color: #6b7280;
        margin-top: -5px;
      }
    }
  }
  .page__questions {
    @include text(15px, 0, "");
    color: #6b7280;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $WHITE;
    .divid {
      display: none;
    }
    .email {
      color: #6b7280;
    }
    .for_further {
      display: flex;
      margin-bottom: 10px;
      flex-direction: row;
    }
    @media screen and (max-width: 900px) {
      .divid {
        display: block;
      }
      .email {
        color: #6b7280;
      }
      .for_further {
        flex-direction: column;
        text-align: center;
      }
      @include text(15px, 0, 400);
      color: #6b7280;
      padding: 30px 20px;
      align-items: center;
    }
  }
}

.new {
  padding: 3px;
  color: white;
  font-size: 12px;
  background: green;
  border: 1px solid green;
  border-radius: 5px;
  position: relative;
}
.new::before {
  content: "";
  position: absolute;
  top: 7px;
  left: -7px;
  height: 0px;
  width: 0px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid green;
}
