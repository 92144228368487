.esg-page {
  // background-color: blue;
  .page__header-intro {
    // background-image: linear-gradient(
    //   to right,
    //   #52c1ec,
    //   #00aff4,
    //   #009bfa,
    //   #0085fb,
    //   #006af5
    // );
    background-color: $WHITE;
    .page__intro {
      position: relative;
      width: 100%;

      // border-radius: 6px;
      // max-width: 1200px;
      // min-height: 600px;
      // padding: 50px 20px;
      // display: flex;
      // justify-content: space-around;
      // align-items: center;

      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .intro__content {
        position: absolute;
        top: 40%;
        left: 2%;
        z-index: 10;
        .content__heading {
          @include text(40px, 0, 600);
          margin: 33px 0px;
        }
        .content__description {
          @include text(18px, 0, "");
          // max-width: 933px;
          width: 100%;
          line-height: 30px;
          margin-bottom: 10px;
          color: $DESC__TEXT;
          @media screen and (max-width: 900px) {
            @include text(13px, 0, "");
            color: $DESC__TEXT;
          }
          .description__heading {
            @include text(30px, 0, 500);
            color: $DESC__TEXT;

            @media screen and (max-width: 900px) {
              @include text(20px, 0, 600);
              color: $DESC__TEXT;
            }
          }
        }
      }
      .intro_pic {
        margin-left: 60px;
        width: 300px;
        @media screen and (max-width: 900px) {
          margin-top: 20px;
          width: 50%;
          object-fit: cover;
          margin-left: 0;
        }
      }
    }
  }
  .esg__container {
    // background-color: white;

    padding: 100px 0px;
    background-image: url("../../assets/sustain.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    background-color: #4299e1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 900px) {
      background-size: 700px;
      resize: both;

      padding: 50px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .esg__heading {
      @include text(22px, 0, 700);
      color: white;
      text-shadow: 1px 1px 5px #000;

      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .esg__dash {
      margin: 20px auto;
      width: 1rem;
      background-color: #fff;
      height: 1px;
      //   margin: auto;
    }
    .esg__description {
      @include text(15px, 0, "");
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      width: 70%;
      margin: auto;
      text-align: center;
      color: white;
      @media screen and (max-width: 900px) {
        width: 90%;
      }
    }
  }

  .page__characters {
    background: $WHITE;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f7fa;
    padding: 40px 0px;

    .characters__heading {
      @include text(22px, "", 700);
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      width: 100%;
      color: $HEADER__TEXT;
    }
    .characters__description {
      @include text(16px, 0, "");
      justify-content: center;
      padding: 20px 0px;
      width: 100%;
      margin: auto;
      text-align: center;
      color: #6b7280;
      &.mobile {
        display: none;
        @media screen and (max-width: 900px) {
          display: flex;
        }
      }
      &.desktop {
        display: flex;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .action {
      text-decoration: none;
    }
  }
  .page__box {
    padding: 40px;
    background: #f5f7fa;

    .box__content {
      padding: 20px;
      width: 320px;
      height: auto;
      background: #f5f7fa;
      border-radius: 5px;

      @media screen and (max-width: 900px) {
        width: 300px;
      }
      .content__title {
        @include text(22px, 0, 700);
        color: $HEADER__TEXT;
      }
      .content__descr {
        @include text(15px, 0, "");
        padding-top: 0.5rem;
        color: #6b7280;
      }
    }
  }
  .page__started {
    margin: 50px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .started_pic {
      margin-right: 60px;
      @media screen and (max-width: 900px) {
        width: 80%;
        object-fit: cover;
        margin-right: 0;
        margin-top: 20px;
      }
    }
    .started__content {
      .content__heading {
        @include text(40px, 0, 600);
        color: $PRIMARY;
        margin: 33px 0px;
      }
      .content__description {
        @include text(20px, 0, "");
        color: #6b7280;
        max-width: 933px;
        line-height: 30px;
        margin-bottom: 10px;
        margin-right: 60px;
      }
    }
  }
}

.text-underline {
  text-decoration: underline;
}
