.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background: $WHITE;
  .header__left {
    cursor: pointer;
    display: flex;
    align-items: center;
    .left__logo {
      width: 210px;
      height: 60px;

      margin-right: 10px;
    }
    .left__title {
      @include text(20px, 0, 600);
      color: $PRIMARY;
      text-decoration: none;
    }
  }
  .header__right {
    display: flex;
    align-items: center;
    .right__text {
      @include text(14px, 0, normal);
      color: #2d3748;
      padding: 0px 20px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      .arrow {
        font-size: 18px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      &:focus .sub-menu,
      &:focus-within .sub-menu,
      &:hover .sub-menu {
        visibility: visible; /* shows sub-menu */
        opacity: 1;
        z-index: 1;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
      }

      &:focus .arrow,
      &:focus-within .arrow,
      &:hover .arrow {
        transform: rotate(180deg);
        transition: all ease-in-out 0.3s;
      }
      &:hover {
        color: $PRIMARY;
      }
      .sub-menu {
        background-color: #fff;
        border-radius: 3px;
        visibility: hidden; /* hides sub-menu */
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        transform: translateY(-2em);
        z-index: -1;
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
          z-index 0s linear 0.01s;
        list-style: none;
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-top: 1rem;
        padding-inline-start: 20px;
        text-align: left;
      }
    }

    .right__mint {
      margin-left: 20px;
    }
  }
}
.color__Black {
  color: $BLACK !important;
}
.text-decoration-none {
  text-decoration: none;
}
// .mobile{
//   display: none;
//   @media screen and (max-width: 900px) {
//     display: block;
//   }
// }
// .desktop{
//   display: block;
//   @media screen and (max-width: 900px) {
//     display: hidden;
//   }
// }

//===========================
//======MOBILE HEADER =======
//===========================

.mobile-header {
  background-color: $WHITE;
  padding: 10px;
  display: flex;
  z-index: 30;
  @media screen and (max-width: 900px) {
    z-index: 12;
  }
  justify-content: space-between;
  padding-left: 0;
  position: relative;

  .header_name {
    display: flex;
    text-decoration: none;
    @include text(18px, 0, 600);
    color: $PRIMARY;
    align-items: center;
    .header-logo {
      width: 210px;
      height: 60px;
    }
  }
  .header-hamberg {
    width: 18px;
  }
}

.menu {
  position: absolute;
  @media screen and (max-width: 900px) {
    z-index: 11;
  }
  z-index: 29;
  width: 100%;
  height: 100%;
  // padding: 1rem 2rem;
  background-color: #fff;
  transition: all;
  transition-duration: 300ms;
  transform: translate(0, 0);

  .menu-items {
    .menu-buttons {
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      border: none;
      outline: none;
      .page-links {
        @include text(14px, 0.13em, normal, capitalize);
        color: $BLACK;
        padding: 20px 0;
        text-decoration: none;

        position: relative;
        .contact-us {
          letter-spacing: 0.13em;
          font-weight: normal;
          text-transform: capitalize;
          font-style: normal;
          font-size: 14px;
          letter-spacing: 0.13em;
          color: #000000;
          text-decoration: none;
        }
        .arrow {
          font-size: 20px;
          position: absolute;
          top: 17px;
          right: -26px;
        }

        .sub-menu {
          visibility: hidden; /* hides sub-menu */
          opacity: 0;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          transform: translateY(-2em);
          z-index: -1;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
            z-index 0s linear 0.01s;
          list-style: none;
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding-inline-start: 20px;
        }
      }
      .page-links:focus .sub-menu,
      .page-links:focus-within .sub-menu,
      .page-links:hover .sub-menu {
        visibility: visible; /* shows sub-menu */
        opacity: 1;
        z-index: 1;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
      }
      .page-links:focus .arrow,
      .page-links:focus-within .arrow,
      .page-links:hover .arrow {
        transform: rotate(180deg);
        transition: all ease-in-out 0.3s;
      }
      .social-link {
        .link {
          margin-right: 30px;
          .link__icon {
            width: 35px;
          }
        }
      }
      .banner__button {
        margin-top: 50px;
        width: 250px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include text(25px, 0, bold, uppercase);
        color: $SECONDRY;
        border: none;
        outline: none;
        background-color: $BLACK;
      }
    }
  }
}
.trans-menu {
  transform: translateY(-102%);
}

.mbl-menu {
  background: #fff;
  box-shadow: 0px 0 4px #a2a1a1;
  top: 0;
  height: auto;
}
.mbl-menu-overlay {
  background: #fff !important;
}

.submenu-link {
  font-size: 14px;
  text-decoration: none;
  padding-block-start: 10px;
  padding-block-end: 10px;
  color: #2d3748;
}

// .menu-items {

//   .menu-buttons {

//     padding-left: 2rem;
//     margin-top: 50px;
//     display: flex;
//     flex-direction: column;
//     justify-content: start;
//     align-items: flex-start;
//     border: none;
//     outline: none;
//     .page-links {
//       @include text(14px, 0.13em,normal, capitalize);
//       color: $BLACK;
//       padding: 20px 0;
//       text-decoration: none;
//     }
//     .social-link {
//       .link {
//         margin-right: 30px;
//         .link__icon {
//           width: 35px;
//         }
//       }
//     }
//     .banner__button {
//       margin-top: 50px;
//       width: 250px;
//       height: 90px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       @include text(25px, '', bold, uppercase);
//       color: $SECONDRY;
//       border: none;
//       outline: none;
//       background-color: $BLACK;
//     }
//   }
// }

// .bm-burger-button {
//   position: fixed;
//   width: 26px;
//   height: 21px;
//   top: 35px;
//   right: 20px;
//   padding: 10px;
//   z-index: 1 !important;
// }

//=======================
//        FOOTER
//=======================
.footer {
  display: flex;
  justify-content: space-between;
  padding: 40px 15px;
  border-top: 2px solid #eeeeee;
  background-color: #f5f7fa;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .footer__left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 900px) {
      padding-bottom: 30px;
      align-items: center;
    }
    .left__logo {
      width: 210px;
      height: 60px;
      margin-top: -12px;
    }
    .bottom__logo {
      width: 200px;
      height: 60px;
      margin-top: 12px;
      position: absolute;
      left: 30%;
      top: 0;
      @media screen and (max-width: 600px) {
        left: -50px;
      }
    }
    .left__title {
      @include text(20px, 0, 600);
      margin-left: 10px;
      margin-top: 8px;
      color: $PRIMARY;
    }
  }
  .footer__right {
    display: flex;
    justify-content: center;
    .right__content {
      .content__title {
        @include text(14px, 0, 600);
        margin-bottom: 10px;
        margin-right: 10px;
        color: #78797a;
      }
      .content__link {
        @include text(12px, 0, normal);
        padding: 5px 10px 5px 0px;
        margin-bottom: 10px;
        margin-right: 10px;
        color: #78797a;
        display: block;
        cursor: pointer;
        &:hover {
          color: $PRIMARY;
        }
      }
    }
  }
}
.footer__copy-right {
  border-top: 1px solid lightgrey;
  @include text(15px, 0, normal);
  text-align: center;
  padding: 20px;
  background-color: #f5f7fa;
  color: #78797a;
}
.border-right-black {
  border-right: 1px solid black;
}
.border-right-gray {
  border-right: 1px solid $DESC__TEXT;
}

//====================================
// COOKIES MODAL
//====================================

.cookies-modal-overlay {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  .cookies-modal {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    background: $WHITE;
    outline: none;
    width: 100%;
    padding: 16px;
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 20px;
      overflow: hidden;
      padding-bottom: 0px;
    }
  }
}

.cookies-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  .content__description {
    @include text(13px, 0, normal);
    width: 60%;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 20px;
    }
    .description__underline {
      color: $PRIMARY;
      text-decoration: underline;
    }
  }
  .content--action {
    margin-right: 50px;
    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      margin-right: 0;
    }
  }
}
.geetest_box_wrap
  .geetest_box
  .geetest_footer
  .geetest_footer_right
  .geetest_box_logo {
  display: none !important;
}

.geetest_captcha.geetest_customTheme .geetest_status_bar,
.geetest_captcha.geetest_customTheme .geetest_box_btn::before,
.geetest_captcha.geetest_customTheme .geetest_box_btn::after,
.geetest_captcha.geetest_customTheme .geetest_gradient_bar,
.geetest_captcha.geetest_customTheme .geetest_bind_status_bar,
.geetest_popup_wrap.geetest_customTheme .geetest_status_bar,
.geetest_popup_wrap.geetest_customTheme .geetest_box_btn::before,
.geetest_popup_wrap.geetest_customTheme .geetest_box_btn::after,
.geetest_popup_wrap.geetest_customTheme .geetest_gradient_bar,
.geetest_popup_wrap.geetest_customTheme .geetest_bind_status_bar {
  background-color: #00a4ef !important;
}
