.contact-page {
  // background-image: linear-gradient(
  //   to right,
  //   #52c1ec,
  //   #00aff4,
  //   #009bfa,
  //   #0085fb,
  //   #006af5
  // );
  .page__heading {
    @include text(22px, "", 700);
    // color: $WHITE;
    // padding: 80px 0px;
    padding: 100px 0px;
    background-image: url("../../assets/contact.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 44%;
    // background-color: #f5f7fa;
    // color: #2d3748;
    color: white;
    text-shadow: 1px 1px 5px #000;
    display: flex;

    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      background-size: 594px;
      background-position: center 35%;
      resize: both;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0px;
    }
  }
  .page__referrence {
    padding: 130px 80px 60px 80px;
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    background: $WHITE;
    @media screen and (max-width: 900px) {
      padding: 30px 20px;
      flex-direction: column;
    }
    .referrence__content {
      flex: 1 0 41%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content__title {
        @include text(22px, 0, 700);
        color: $HEADER__TEXT;
        @media screen and (max-width: 900px) {
          @include text(18px, 0, 700);
          color: $HEADER__TEXT;
        }
      }
      .content__descr {
        padding: 20px;
        @include text(18px, 0, 400);
        border-radius: 100px;
        cursor: pointer;
        text-decoration: none;
        color: #6b7280;

        @media screen and (max-width: 900px) {
          @include text(15px, 0, 400);
          color: #6b7280;
          padding: 10px 0px 20px;
        }
        &:hover {
          color: $PRIMARY;
        }
      }
    }
  }

  .pgp {
    border: 1px solid #e5e7eb;
    padding: 10px;
    margin: 0 auto;
    border-radius: 5px;
    max-width: 50%;
    word-break: break-all;
    word-wrap: break-word;
    @media screen and (max-width: 900px) {
      max-width: 100%;
      font-size: 14px;
    }
  }
  .page__questions {
    @include text(15px, 0, "");
    color: #6b7280;
    padding: 10px 0px 40px 80px;

    background-color: $WHITE;
    text-align: center;

    @media screen and (max-width: 900px) {
      @include text(12px, 0, 400);
      color: #6b7280;
      padding: 30px 20px;
    }
  }
}
