.login-page {
  background-color: $WHITE;
  padding: 8px;
  .error {
    color: red;
    text-align: center;
    font-size: medium;
  }
  .page__heading {
    @include text(22px, 0, 700);
    padding: 33px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: $DESC__TEXT;
    color: #2d3748;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .page__box {
    padding: 60px 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px auto;
    background: #f5f7fa;
    border-radius: 5px;
    @media screen and (max-width: 900px) {
      padding: 10px;
      margin: 0px;
      width: 100%;
    }

    .box__label {
      @include text(15px, 0, "");
      padding-top: 15px;
    }
    input {
      @include text(15px, 0, "");
      box-sizing: border-box;
      border: 1px solid rgb(234, 236, 239);
      border-radius: 4px;
      margin: 0px;
      width: 100%;
      height: 48px;
      padding: 0px;
      outline: none;
      background: white !important;
      opacity: 1;
    }
    .box__forgot {
      @include text(15px, 0, "");
      padding-top: 15px;
      padding-bottom: 15px;
      text-decoration: underline;
    }
  }
}
::placeholder {
  @include text(12px, 0, "");
}

//=====================
//      SITE POLICY
//=====================
.site-policy {
  display: flex;
  flex-direction: column;

  background-color: #fff !important ;
  .policy__heading {
    padding: 40px 80px;

    background-image: linear-gradient(
      to right,
      #52c1ec,
      #00aff4,
      #009bfa,
      #0085fb,
      #006af5
    );
    @include text(22px, 0, 700);
    color: $WHITE;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .policy__sub-heading {
    padding: 20px 150px;

    @include text(15px, 0, 600);
    color: #78797a;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
      padding: 20px;
    }
  }
  .policy__description {
    padding: 0 150px 10px;
    .policy__link {
      text-decoration: underline;
      color: $PRIMARY;
    }

    @include text(12px, 0, "");
    color: #78797a;
    padding-bottom: 10px;
    @media screen and (max-width: 900px) {
      padding: 0px 20px 10px;
    }
  }
}

a {
  font-weight: normal;
  text-transform: initial;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0;
  color: #4299e1;
  display: block;
  cursor: pointer;
}
