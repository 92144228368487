.heading {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #4299e1;
  img {
    margin-right: 0.8rem;
    transform: scaleX(-1) rotate(174deg);
  }
}
.verify__container {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}
.confirm {
  color: #2d3748;
  display: flex;
  align-items: center;
  padding: 0.8rem 0;

  a {
    margin-left: 5px;
    font-size: 1rem;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}
.verify-input-container {
  max-width: 400px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  position: relative;
  display: flex;
  @media screen and (max-width: 900px) {
    max-width: 375px;
  }
}
.verify-input {
  border-radius: 50px;
  padding: 10px;
  border: 1px solid rgb(234, 236, 239);
  width: 100%;
  &::placeholder {
    color: #7d7f81;
  }
}
.search {
  position: absolute;
  top: 21px;
  right: 5px;
  border: none;
  border-radius: 47px;
  cursor: pointer;
  padding: 6px;
  background-color: #00a4ef;
  color: white;
}
.warning {
  text-align: center;
  font-size: 0.8rem;
  padding-top: 0.8rem;
  color: #7d7f81;
  max-width: 500px;
}

.popup {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}
.popup-not-verified {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgb(234, 236, 239);
  text-align: center;
  max-width: 400px;
  padding: 10px;
  color: rgb(153, 45, 45);
}
.popup-verified {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgb(234, 236, 239);
  text-align: center;
  max-width: 400px;
  padding: 10px;
  color: rgb(57, 135, 57);
}
.contact_us {
  display: inline-block;
  text-decoration: none;
  color: #4299e1;
}
